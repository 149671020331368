import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sumpayment"
export default class extends Controller {
    static targets = [ "subtotal", "grandtotal", "amount", "donation", "donate" ]

    connect() {
        console.log("Connected to sumpayment controller!")
        this.sum();
    }

    sum() {
        let total = 0.00
        let grandTotal = 0.00
        let ccDonationAmount = 0.00
        let grandTotalAmount = 0.00

        this.subtotalTargets.forEach((element) => {
            if (element.textContent != "" && !element.classList.contains("line-through") && !(element.offsetParent === null)) {
                total = total + parseFloat(element.textContent);
                //grandTotal = grandTotal + parseFloat(element.textContent);

            }
            if (this.hasDonationTarget) {
                ccDonationAmount = total * .02
                this.donationTarget.textContent = ccDonationAmount.toFixed(2);
                if (!this.donateTarget.checked) {
                    this.donationTarget.classList.add("line-through")
                }
            }
        })


        if (this.hasDonationTarget && !this.donationTarget.classList.contains("line-through") ) {
            grandTotalAmount = total + ccDonationAmount; 
        } else {
            grandTotalAmount = total
        }
        this.grandtotalTarget.textContent = grandTotalAmount.toFixed(2);


        // used in pay.html.erb
        if (this.hasAmountTarget) {
            console.log("In the IF statement")
            this.amountTarget.value = grandTotal.toFixed(2);
            console.log("VALUE: " + this.amountTarget.value)
        }
    }

    donate() {
        if (this.donateTarget.checked) {
            this.donationTarget.classList.remove("line-through")
        } else {
            this.donationTarget.classList.add("line-through")
        }

    }


}
