import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dialog"
export default class extends Controller {
  static targets = ["dialogmodal"]

  connect() {
  }

  open() {
    // this.modalTarget.show();
    this.dialogmodalTarget.showModal();
    document.body.classList.add("overflow-hidden")
  }

  clickOutside(event) {
    if (event.target === this.dialogmodalTarget) {
      this.dialogmodalTarget.close();
    }
  }

  close() {
    
  }

}
