import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="processing"
export default class extends Controller {
    static targets = [ 'busy' ]

    connect() {
        console.log('Connected to Processing Controller')
    }

    busy() {
        console.log("YES, WE ARE BUSY!")
        this.busyTarget.classList.remove("hidden")
    }
}
