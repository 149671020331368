import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    static targets = [ "hideable" ]

    connect() {

    }


    showTargets() {
        this.hideableTargets.forEach(el => {
            el.hidden = false;
            //el.classList.remove("hidden")
            console.log("We are in show targets.")
        });
    }

    hideTargets() {
        this.hideableTargets.forEach(el => {
            el.hidden = true;
            //el.classList.add("hidden")
        });
    }

    toggleTargets() {
        this.hideableTargets.forEach(el => {
            console.log("We are trying yet again!!!")
            el.hidden = !el.hidden;

        });
    }

}