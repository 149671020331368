import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="manual-schedule-select"
export default class extends Controller {
  static targets = ["studentChoice"]
  static values = { evt: String }

  change(event) { 
    let teacherId = event.target.selectedOptions[0].value
    let target = this.studentChoiceTarget.id
    
    get(`/manual_schedules/load_students?event_id=${this.evtValue}&target=${target}&teacher_id=${teacherId}`, {
      responseKind: "turbo-stream"
    })
  }

}
