import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="subtotal"
export default class extends Controller {

    static targets = [ 'checkbox', 'amount', 'quantity', 'subtotalsum' ]

    connect() {
    }

    start() {
        if (this.checkboxTarget.checked) {
            //console.log("Hurray, Checked")
            //console.log("Amount: " + this.amountTarget.textContent)
            //console.log(this.quantityTarget.value)
            //console.log("Quantity: " + this.quantityTarget.value)
            let amountFloat = parseFloat(this.amountTarget.textContent)

            let total = amountFloat * this.quantityTarget.value
            //console.log("TOTAL: " + total)
            this.subtotalsumTarget.textContent = total.toFixed(2);
        } else {
            this.subtotalsumTarget.textContent = "";
        }


    }
}
